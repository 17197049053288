<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="file" label="File">
            <div class="clearfix">
              <a-upload :file-list="fileList" :before-upload="beforeUpload" :remove="handleRemove">
                <a-button>
                  <upload-outlined></upload-outlined>
                  Select File
                </a-button>
              </a-upload>
            </div>
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton
                  type="primary"
                  :disabled="fileList.length === 0"
                  :loading="uploading"
                  style="margin-top: 16px"
                >
                  {{ uploading ? 'Uploading' : 'Submit' }}
                </sdButton>

                <router-link to="/master/vendor">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>

      <sdCards v-if="responseData" headless>
        <!-- <h3>{{ responseData.message }}</h3> -->
        <div class="sDash_form-action">
          <sdButton
            type="primary"
            @click="handleConfirm"
            :loading="confirming"
            style="margin-top: 15px; margin-bottom: 15px; margin-right: 15px"
          >
            {{ confirming ? 'Mengkonfirmasi' : 'Konfirmasi' }}
          </sdButton>
          <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button" @click="handleCancel">
            Batal
          </sdButton>
        </div>
        <a-table
          :dataSource="responseData.data"
          :columns="columns"
          rowKey="nip"
          :rowClassName="setRowClassName"
          :pagination="{ pageSize: 10 }"
        />
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { UploadOutlined } from '@ant-design/icons-vue';
import { ref, reactive, watch, computed } from 'vue';
import { useStore } from 'vuex';

const VendorUploadForm = {
  name: 'VendorForm',
  components: { HorizontalFormStyleWrap, Main, UploadOutlined },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.crud.loading);
    const fileList = ref([]);
    const uploading = ref(false);
    const confirming = ref(false);
    const formState = reactive({
      file: null,
    });
    const responseData = ref(null); // Menyimpan data respons

    const setRowClassName = record => {
      return record.description ? 'row-with-description' : '';
    };

    const columns = [
      { title: 'Nama', dataIndex: 'nama', key: 'nama' },
      { title: 'NIK', dataIndex: 'nik', key: 'nik' },
      { title: 'Perusahaan', dataIndex: 'perusahaan', key: 'perusahaan' },
      { title: 'NPWP', dataIndex: 'npwp', key: 'npwp' },
      {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        customRender: is_active => {
          console.log('is_active value:', is_active.text);
          if (is_active.text === true) {
            return 'Aktif';
          } else if (is_active.text === false) {
            return 'Non Aktif';
          } else {
            return '-';
          }
        },
      },
      { title: 'Jabatan', dataIndex: 'jabatan', key: 'jabatan' },
      { title: 'No HP', dataIndex: 'no_hp', key: 'no_hp' },
      { title: 'No Telp', dataIndex: 'no_telp', key: 'no_telp' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Keterangan', dataIndex: 'description', key: 'description' },
      // { title: 'File Name', dataIndex: 'file_name', key: 'file_name' },
    ];

    watch(fileList, newFileList => {
      formState.file = newFileList.length > 0 ? newFileList[0].name : null;
    });

    const beforeUpload = file => {
      fileList.value = [file];
      return false;
    };

    const handleRemove = file => {
      const index = fileList.value.indexOf(file);
      if (index !== -1) {
        fileList.value.splice(index, 1);
      }
    };

    const handleSubmit = async () => {
      const formData = new FormData();
      fileList.value.forEach(file => {
        formData.append('file', file);
      });
      uploading.value = true;

      try {
        const response = await dispatch('axiosCrudSubmitDataNoRedirect', {
          url: 'upload-template-vendor',
          data: formData,
        });
        console.log(response.data);

        responseData.value = response;
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        uploading.value = false;
      }
    };

    const handleConfirm = async () => {
      confirming.value = true;

      dispatch('axiosCrudSubmitData', {
        url: 'confirm-upload-template-vendor',
        data: {
          file_name: responseData.value.file_name,
        },
      });
    };

    const handleCancel = () => {
      dispatch('axiosCrudSubmitData', {
        url: 'cancel-upload-template-vendor',
        data: {
          file_name: responseData.value.file_name,
        },
      });
    };

    return {
      fileList,
      uploading,
      confirming,
      formState,
      handleRemove,
      beforeUpload,
      handleSubmit,
      handleConfirm,
      handleCancel,
      isLoading,
      responseData,
      setRowClassName,
      columns,
    };
  },
  props: ['mode', 'module'],
  data() {
    return {
      title: this.mode + ' ' + this.module,
      labelCol: {
        lg: 6,
        md: 9,
        xs: 24,
      },
      wrapperCol: {
        lg: 18,
        md: 15,
        xs: 24,
      },
      rules: {
        file: [{ required: true, message: 'File wajib dipilih', trigger: 'change' }],
      },
    };
  },
};

export default VendorUploadForm;
</script>

<style>
.row-with-description {
  background-color: #fac898 !important;
}
</style>
